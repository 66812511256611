import { useState } from "react"
import styled, { css } from "styled-components";
import { device } from '../styles/stylesConfig';
import SocialMenu from "./SocialMenu";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading } from "../actions/ui";

const LogoContainer = styled.div<{ inprogress?: string }>`
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    ${({ inprogress }) => inprogress === 'true' && css`
        height: 35vh;

        @media ${device.sm} {
            height: 25vh;
        }
    `}

`

const LogoDiv = styled.div`
    position: relative;
    max-width: 65%;
    width: 60vh;
    height: 60vh;
    z-index: 2;
    display: flex;
    justify-content: center;
`

const Logo = styled.img<{ inprogress?: string, preloader?: string }>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;
    
    ${({ inprogress }) => inprogress === 'true' && css`
        height: 40%;
        align-self: center;
        justify-self: center;
    `}

    ${({ preloader }) => preloader === 'true' && css`
        width: 200px;
        height: 200px;

        @media ${device.sm} {
        }

        @media ${device.sm} {
        }

        @media ${device.sm} {
        }
        
        @media ${device.sm} {
        }
    `}
`


const BackgroundLoading = styled.div`
    
    @keyframes temperature {
        0% {
            height: 0px;
            bottom: 0px;
            right: 0px;
        }

        100% {
            height: 80px;
            bottom: 100px;
            right: 30px;
        }
    }

    width: 25px;
    height: 0px;
    background-color: #CE0B0B;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0px;
    right: 0px;
    border-radius: 30px;
    margin: auto;
    z-index: 1;
    transform: rotate(-20deg);

    &.active {
        animation: temperature 1s ease-in-out forwards 0.5s;
    }

`

const OldSchoolReggaeton = styled.img`
    max-width: 40%;
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 10vh;
    right: -5vh;
    z-index: 0;

    @media ${device.lg} {
        width: 12vh;
        bottom: 12vh;
        right: -4vh;
    }
    
    @media ${device.md} {
        width: 12vh;
        bottom: 15vh;
        right: -5vh;
    }

    @media ${device.sm} {
        width: 10vh;
        bottom: 17vh;
        right: -4vh;
    }

    @media ${device.xs} {
        bottom: 20vh;
        right: -4vh;
    }
`

const Tape = styled.img`
    position: absolute;
    height: 75vh;
    width: 100%;
    z-index: 1;
`

const Barcode = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -1.6vh;
    right: 5vw;
    z-index: 1;
    transform: rotate(2deg);

    @media ${device.lg} {
        bottom: -1vh;
        transform: rotate(0deg);
        width: 13vh;
    }
    
    @media ${device.md} {
        bottom: -0.7vh;
        width: 12vh;
    }

    @media ${device.sm} {
        bottom: -0vh;
        width: 10vh;
        transform: rotate(-3deg);
    }
`

const FireLogo = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -9vh;
    left: 5vw;
    z-index: 1;
    transform: rotate(-9deg);

    
    @media ${device.lg} {
        width: 18vh;
        bottom: -8vh;
    }
    
    @media ${device.md} {
        width: 15vh;
        bottom: -6.5vh;
    }

    @media ${device.sm} {
        width: 13vh;
        bottom: -5vh;
    }
`

interface Props {
    inProgress?: boolean;
    preloader?: boolean;
}

const LogosComponent = ({ inProgress, preloader }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const [imageLoaded, setImageLoaded] = useState(false);

    const dispatch = useDispatch()

    return (

        <LogoContainer inprogress={inProgress ? inProgress.toString() : 'false'} id='home'>

            {
                !inProgress && (

                    <>

                        <SocialMenu inProgress={inProgress} direction="column" />

                        <Tape
                            loading="eager"
                            // src={require(`../images/introduction/cinta.png`)}
                            src={`${process.env.REACT_APP_CDN}/cinta.webp`}
                            alt="Tape"
                            onLoad={() => { dispatch(finishLoading()); }}
                        />

                    </>

                )
            }

            <LogoDiv className={imageLoaded && preloader ? '' : ''}>

                {
                    !inProgress && (

                        <OldSchoolReggaeton
                            className={!loading ? 'utils__fade-in-center' : ''}
                            loading="eager"
                            // src={require(`../images/introduction/old-school.png`)}
                            src={`${process.env.REACT_APP_CDN}/old-school.webp`}
                            alt="Old School Reggaeton"
                        />

                    )
                }

                <Logo
                    preloader={preloader ? preloader.toString() : 'false'}
                    inprogress={inProgress ? inProgress.toString() : 'false'}
                    className={!loading && !inProgress ? 'utils__fade-in-bottom active' : ''}
                    loading="eager"
                    alt="Fiebre"
                    // src={!inProgress
                    //     ? require(`../images/introduction/fiebre-logo.png`)
                    //     : `${!preloader
                    //         ? require(`../images/introduction/fiebre-ovalo.png`)
                    //         : require(`../images/general/tt.png`)
                    //     }`
                    // }
                    src={!inProgress
                        ? `${process.env.REACT_APP_CDN}/fiebre-logo.webp`
                        : `${!preloader
                            ? `${process.env.REACT_APP_CDN}/fiebre-ovalo.png`
                            : `${process.env.REACT_APP_CDN}/tt.webp`
                        }`
                    }
                    onLoad={() => { setImageLoaded(true) }}
                />

                {preloader && <BackgroundLoading className={imageLoaded ? 'active' : ''} />}

            </LogoDiv>

            {
                !inProgress && (
                    <>

                        <Barcode
                            className={!loading ? 'utils__fade-in-left' : ''}
                            loading="eager"
                            // src={require(`../images/introduction/barcode.png`)}
                            src={`${process.env.REACT_APP_CDN}/barcode.png`}
                            alt="Barcode"
                        />

                        <FireLogo
                            className={!loading ? 'utils__fade-in-right' : ''}
                            loading="eager"
                            // src={require(`../images/introduction/sticker-logo-llama.png`)}
                            src={`${process.env.REACT_APP_CDN}/sticker-logo-llama.webp`}
                            alt="FireLogo"
                        />

                    </>

                )
            }

        </LogoContainer>
    )
}

const Logos = LogosComponent;

export default Logos;