import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useDispatch } from "react-redux"
import { startLoading } from "../actions/ui"
import { useLogEvent } from "../hooks/useLogEvent"
import { useHistory } from "react-router-dom"
import { TicketProps } from '../constants/TextConstants';
import { useEffect, useState } from "react"
import { allAnimations } from "../effects/Observers"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TicketContainer = styled.button<{ img: string }>`
    width: 25vw;
    height: 25vw;
    max-width: 350px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.25);
    margin-top: 100px;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: cover;
    `}

    @media ${device.lg} {
        width: 30vw;
        height: 30vw;
    }

    @media ${device.md} {
        width: 40vw;
        height: 40vw;

        margin-top: 50px;
    }

    @media ${device.sm} {
        width: 50vw;
        height: 50vw;

        margin-top: 50px;
    }

    &:first-of-type {
        margin-top: 0px;
    }

`

const SendButton = styled.button`
    width: fit-content;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    background-color: #D90B0B;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 25px;
    border-radius: 10px;
    margin-top: 50px;
    letter-spacing: 1px;
    z-index: 3;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #920505;
        transform: scale(1.02);
    }


    @media ${device.lg} {
        font-size: 12px;
        padding: 12px 25px;
    }

    @media ${device.md} {
        align-self: center;
        margin-top: 30px;

    }

    @media ${device.sm} {
        font-size: 10px;
        padding: 7.5px 20px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

const Tape = styled.img`
    width: 350px;
    height: auto;
    position: absolute;
    margin: auto;
    top: -130px;
    left: 0;
    right: 0;
    z-index: 1;
    transform: rotate(35deg);

    @media ${device.lg} {
        width: 300px;
        top: -110px;
    }
    
    @media ${device.md} {
        width: 200px;
        top: -75px;
    }

    @media ${device.sm} {
        width: 150px;
        top: -60px;
    }

    @media ${device.xs} {
        width: 100px;
        top: -40px;
    }

`

interface Props {
    ticket: TicketProps;
}

export const Ticket = ({ ticket }: Props) => {

    const dispatch = useDispatch();

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();

    const getCurrentUTCTime = () => {
        const now = new Date();
        return now.toUTCString();
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const targetTimeUTC = new Date(ticket.showSince);
        const interval = setInterval(() => {
            const currentTimeUTC = new Date(getCurrentUTCTime());

            if (currentTimeUTC >= targetTimeUTC) {
                setIsVisible(true);
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };


    }, [])

    return (

        <>

            {
                isVisible && (

                    <Container key={ticket.flyer} >

                        <TicketContainer
                            // img={require(`../images/tickets/080624-black.png`)}
                            img={`${process.env.REACT_APP_CDN}/${ticket.flyer}`}
                            onClick={async () => {

                                dispatch(startLoading());

                                await history.push(`/${ticket.goTo}`);

                                handleLogEvent(`click_boton_mas_informacion_${ticket.ticketEvent}`)


                            }}
                        >
                            <Tape
                                loading="eager"
                                // src={require(`../images/stickers/cinta-fina.png`)}
                                src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                                alt="Cinta"
                            />
                        </TicketContainer>

                        <SendButton
                            onClick={async () => {

                                window.open(ticket.ticketLink, "_blank");

                                handleLogEvent(`click_boton_entrada_${ticket.ticketEvent}`)

                            }}
                        >
                            Quiero mi entrada
                        </SendButton>

                    </Container>

                )
            }

        </>



    )
}

export default Ticket;