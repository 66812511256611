import { CSSProperties, memo } from "react";
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

const ScreenContainerComponent = ({ id, children, style }: { id?: string, children?: React.ReactNode, style?: CSSProperties }) => {

    return (

        <Container id={id} style={{ ...style }}>

            {children}

        </Container>

    )
}

const ScreenContainer = memo(ScreenContainerComponent);

export default ScreenContainer;