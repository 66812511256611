import styled, { css } from "styled-components"
import { device } from "../../styles/stylesConfig"
import { globalTexts, TicketProps } from "../../constants/TextConstants"
import { getCurrentUTCTime, getTicketsList } from "../../utils/Utilities"
import TicketSummer from "./TicketSummer"

const Container = styled.section`
    width: 100vw;
    padding: 10vh 0px 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media ${device.md} {
        padding: 5vh 0px 10vh;
    }
`

const Title = styled.p`
    width: 95%;
    font-weight: 900;
    font-size: 36px;
    color: #FFF;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    margin-bottom: 7vh;
    letter-spacing: 10px;
    line-height: 70px;

    @media ${device.lg} {
        font-size: 24px;
        line-height: 50px;
        margin-bottom: 4vh;
        letter-spacing: 5px;
    }

    @media ${device.md} {
        margin-top: 5vh;
        margin-bottom: 3vh;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
    }

    @media ${device.sm} {
        margin-top: 10vh;
        margin-bottom: 4vh;
        font-size: 16px;
        letter-spacing: 1px;
    }

    @media ${device.xs} {
        margin-top: 7vh;
        font-size: 14px;
    }
`

const TicketsList = styled.div<{ listlength: number }>`
    width: auto;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;
    z-index: 5;

    ${props => props.listlength % 2 !== 0 && css`

        & > div:nth-of-type(${props.listlength}) {
            grid-column: 1 / 3;
        }

        
    @media ${device.md} {
        & > div:nth-of-type(${props.listlength}) {
            grid-column: unset;
        }
    }

    `}

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }

    @media ${device.md} {
        gap: 70px;
    }

`

const OpenSeason = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 15vh;
    right: -2vw;
    z-index: 0;
    transform: rotate(20deg);

    @media ${device.lg} {
        width: 15vh;
    }

    @media ${device.md} {
        top: 20vh;
    }
  
`

const Reggaeton = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 20vh;
    right: 0vw;
    z-index: 0;
    transform: rotate(20deg);

    @media ${device.lg} {
        width: 10vh;
    }

    @media ${device.md} {
        top: 25vh;
    }
`

//


const Yellow2025 = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 35vh;
    left: 0vw;
    z-index: 0;

    @media ${device.lg} {
        width: 10vh;
        top: 15vh;
    }

    @media ${device.md} {
        top: 60vh;
    }
  
`

const OldCurve = styled.img`
    width: 40vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 38vh;
    left: -7vw;
    z-index: 0;
    transform: rotate(-10deg);

    @media ${device.lg} {
        width: 30vh;
        left: -15vw;
        top: 18vh;
    }

    @media ${device.md} {
        top: 65vh;
    }
  
`

//


const Fire2025 = styled.img`
    width: 10vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 58vh;
    right: 1vw;
    z-index: 1;
    transform: rotate(-10deg);

    @media ${device.lg} {
        width: 7vh;
        top: 32vh;
    }

    @media ${device.md} {
        top: 117vh;
    }
  
`

const Faro = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 60vh;
    right: 0vw;
    z-index: 0;
    transform: rotate(-40deg);

    @media ${device.lg} {
        width: 12vh;
        top: 35vh;
    }

    @media ${device.md} {
        top: 120vh;
    }
  
`

const OrangeSandals = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 75vh;
    right: -2vw;
    z-index: 0;
    transform: rotate(20deg);

    @media ${device.lg} {
        width: 7vh;
        top: 42vh;
    }

    @media ${device.md} {
        top: 127vh;
    }
  
`

//


const Package = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 110vh;
    left: 7vw;
    z-index: 1;
    transform: rotate(-10deg);

    
    @media ${device.lg} {
        width: 15vh;
        left: 3vw;
        top: 60vh;
    }

    @media ${device.md} {
        top: 150vh;
    }
`

const SummerBlue = styled.img`
    width: 30vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 138vh;
    left: -1vw;
    z-index: 0;

    @media ${device.lg} {
        width: 20vh;
        top: 78vh;
        left: -5vw;
    }

    @media ${device.md} {
        top: 168vh;
    }

`

//


const FiebreMdq = styled.img`
    width: 30vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 115vh;
    right: -1vw;
    z-index: 1;
    transform: rotate(-10deg);

    @media ${device.lg} {
        width: 20vh;
        top: 70vh;
    }

    @media ${device.md} {
        top: 200vh;
    }
  
`

const SummerOrange = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 128vh;
    right: 10vw;
    z-index: 1;

    @media ${device.lg} {
        width: 10vh;
        top: 78vh;
    }

    @media ${device.md} {
        top: 210vh;
    }
  
`

const BlackSandals = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 125vh;
    right: -2vw;
    z-index: 2;
    transform: rotate(20deg);

    @media ${device.lg} {
        width: 10vh;
        top: 75vh;
    }

    @media ${device.md} {
        top: 207vh;
    }
  
`

//


const OldOrange = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 170vh;
    right: 5vw;
    z-index: 1;
    transform: rotate(-10deg);

    @media ${device.lg} {
        width: 15vh;
        top: 100vh;
        right: 0vw;
    }

    @media ${device.lg} {
        top: 300vh;
    }
  
`

const Waves = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 175vh;
    right: 0vw;
    z-index: 0;
    transform: rotate(10deg);

    @media ${device.lg} {
        width: 10vh;
        top: 102vh;
    }
    
    @media ${device.lg} {
        top: 302vh;
    }
  
`

const RedSandals = styled.img`
    width: 12vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 195vh;
    right: 0vw;
    z-index: 2;

    @media ${device.lg} {
        width: 7vh;
        top: 110vh;
    }
  
    @media ${device.lg} {
        top: 312vh;
    }
`

//


const JanuaryBlue = styled.img`
    width: 30vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 200vh;
    left: 0vw;
    z-index: 0;
    transform: rotate(-10deg);
    
    
    @media ${device.lg} {
        width: 15vh;
        top: 125vh;
        left: -2vw;
    }

    @media ${device.md} {
        top: 250vh;
    }
  
`

const OrangeSandals2 = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 205vh;
    left: 10vw;
    z-index: 1;
    transform: rotate(-20deg);

    @media ${device.lg} {
        width: 10vh;
        top: 130vh;
        left: 2vw;
    }

    @media ${device.md} {
        top: 255vh;
    }

`

//


const Red2025 = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 45vh;
    right: 5vw;
    z-index: 0;
    transform: rotate(10deg);

    @media ${device.lg} {
        width: 15vh;
        bottom: 30vh;
        right: -2vw;
    }

    @media ${device.md} {
        bottom: 50vh;
    }
  
    @media ${device.sm} {
        bottom: 30vh;
    }
`

const FireRed = styled.img`
    width: 10vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 40vh;
    right: 5vw;
    z-index: 1;

    @media ${device.lg} {
        width: 7vh;
        bottom: 26vh;
        right: -2vw;
    }

    @media ${device.md} {
        bottom: 46vh;
    }
  
    @media ${device.sm} {
        bottom: 26vh;
    }
`

//

const BlueSandals = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -5vh;
    left: 10vw;
    z-index: 0;
    
    
    @media ${device.lg} {
        width: 15vh;
        bottom: 0vh;
        left: 5vw;
    }
  
`

const Eyes = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 5vh;
    left: 12vw;
    z-index: 1;
    transform: rotate(20deg);

    @media ${device.lg} {
        width: 15vh;
        bottom: 5vh;
        left: 10vw;
    }

`

//


export const TicketSectionSummer = () => {

    const getListLength = () => {

        const list = getTicketsList(globalTexts.ticketsSummer).filter((value: TicketProps) => {

            const targetTimeUTC = new Date(value.showSince);

            const currentTimeUTC = new Date(getCurrentUTCTime());

            if (currentTimeUTC >= targetTimeUTC) {
                return value
            }
        })

        return list.length
    }

    return (

        <Container>

            <Title className='utils__fade-in-fast-top'>¡Compra tu entrada ahora!</Title>

            <TicketsList listlength={getListLength()}>
                {
                    getTicketsList(globalTexts.ticketsSummer).map((ticket: TicketProps) => {

                        const targetTimeUTC = new Date(ticket.showSince);

                        const currentTimeUTC = new Date(getCurrentUTCTime());

                        if (currentTimeUTC >= targetTimeUTC) {

                        } else return

                        return (

                            <TicketSummer ticket={ticket} key={ticket.flyer} />

                        )

                    })
                }

            </TicketsList>

            <OpenSeason
                src={`${process.env.REACT_APP_CDN}/verano2025/open-season.webp`}
                alt="OpenSeason"
            />

            <Reggaeton
                src={`${process.env.REACT_APP_CDN}/verano2025/reggaeton.webp`}
                alt="Reggaeton"
            />

            <Yellow2025
                src={`${process.env.REACT_APP_CDN}/verano2025/yellow2025.png`}
                alt="Yellow2025"
            />

            <OldCurve
                src={`${process.env.REACT_APP_CDN}/verano2025/old-redondo.webp`}
                alt="OldCurve"
            />

            {/* <Fire2025
                src={`${process.env.REACT_APP_CDN}/verano2025/2025-fire.png`}
                alt="Fire2025"
            /> */}

            {/* <Faro
                src={`${process.env.REACT_APP_CDN}/verano2025/faro.png`}
                alt="Faro"
            /> */}

            {/* <OrangeSandals
                src={`${process.env.REACT_APP_CDN}/verano2025/ojotas-rojo.png`}
                alt="OrangeSandals"
            />

            <Package
                src={`${process.env.REACT_APP_CDN}/verano2025/valija.png`}
                alt="Package"
            />

            <SummerBlue
                src={`${process.env.REACT_APP_CDN}/verano2025/verano-azul.png`}
                alt="SummerBlue"
            /> */}

            {/* <FiebreMdq
                src={`${process.env.REACT_APP_CDN}/verano2025/fiebre-old-mdq.png`}
                alt="FiebreMdq"
            />

            <SummerOrange
                src={`${process.env.REACT_APP_CDN}/verano2025/verano-naranja.png`}
                alt="SummerOrange"
            />

            <BlackSandals
                src={`${process.env.REACT_APP_CDN}/verano2025/ojotas-negro.png`}
                alt="BlackSandals"
            /> */}

            {/* <OldOrange
                src={`${process.env.REACT_APP_CDN}/verano2025/old-naranja.webp`}
                alt="OldOrange"
            />

            <Waves
                src={`${process.env.REACT_APP_CDN}/verano2025/olas.png`}
                alt="Waves"
            />

            <RedSandals
                src={`${process.env.REACT_APP_CDN}/verano2025/ojotas-rojas.png`}
                alt="RedSandals"
            /> */}

            {/* <JanuaryBlue
                src={`${process.env.REACT_APP_CDN}/verano2025/enero-azul.png`}
                alt="JanuaryBlue"
            />

            <OrangeSandals2
                src={`${process.env.REACT_APP_CDN}/verano2025/ojotas-naranja.png`}
                alt="OrangeSandals2"
            /> */}

            {/* <Red2025
                src={`${process.env.REACT_APP_CDN}/verano2025/2025-rojo.png`}
                alt="Red2025"
            />

            <FireRed
                src={`${process.env.REACT_APP_CDN}/verano2025/fiebre-circular.webp`}
                alt="FireRed"
            /> */}

            {/* <BlueSandals
                src={`${process.env.REACT_APP_CDN}/verano2025/ojotas-azul.png`}
                alt="BlueSandals"
            />

            <Eyes
                src={`${process.env.REACT_APP_CDN}/verano2025/anteojos.png`}
                alt="Eyes"
            /> */}


        </Container >


    )
}

export default TicketSectionSummer;