import { getCurrentUTCTime, getDate } from "../utils/Utilities";

export interface TicketProps {
    flyer: string;
    goTo: string;
    ticketLink: string;
    ticketEvent: string;
    tablesLink: string;
    locationLink: string;
    showSince: string;
    showUntil: number;
    disabled?: boolean
}

interface IntroTextsProps {
    wantMyTicketAndMoreInfoIntroduction: TicketProps;
}

interface GlobalTextsProps {
    tickets: TicketProps[];
    ticketsSummer: TicketProps[];
    whatsAppGroupLink: string;
    instagramLink: string;
    tiktokLink: string;
    twitterLink: string;
    spotifyLink: string;
    picturesLink: string;
}

export const globalTexts: GlobalTextsProps = {
    tickets: [
        {
            flyer: '221124-groove.webp',
            goTo: '221124-groove',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-groove-2211',
            ticketEvent: '22/11/24_groove',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9688531391407!2d-58.4257572874254!3d-34.57965465611708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb59b4e07a685%3A0x57be92636048e0f9!2sGroove!5e0!3m2!1ses!2sar!4v1720909728659!5m2!1ses!2sar',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20241122
        },
        {
            flyer: '131224-cruza.webp',
            goTo: '131224-cruza',
            ticketLink: 'https://www.passline.com/eventos/fiebre-en-cruza-polo-1312',
            ticketEvent: '13/12/24_cruza_polo',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.372411272314!2d-58.43198067253831!3d-34.56944228576349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb57ef0e17391%3A0x1eaba36a56f16fe2!2sCruza%20Polo!5e0!3m2!1ses!2sar!4v1732920267412!5m2!1ses!2sar',
            showSince: '2024-11-29T22:59:00Z',
            showUntil: 20241213
        },
        {
            flyer: '070225-black.webp',
            goTo: '070225-black',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-black-72',
            ticketEvent: '07/02/25_black',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.874584080714!2d-58.4483721!3d-34.556730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5eb0d0f6a89%3A0x64f7cfb36b48c654!2sBlack!5e0!3m2!1ses!2sar!4v1716311869803!5m2!1ses!2sar',
            showSince: '2024-12-23T22:59:00Z',
            showUntil: 20250207
        },
    ],
    whatsAppGroupLink: 'https://chat.whatsapp.com/DvTmBLdUjh4KGyb4g3ATdq',
    instagramLink: 'https://www.instagram.com/fiebreflow/',
    tiktokLink: 'https://www.tiktok.com/@fiebre.fiesta',
    twitterLink: 'https://twitter.com/fiebrefiesta',
    spotifyLink: 'https://open.spotify.com/playlist/1H3SYVXxljbApZxgIWxh2U?si=a54WonX9SryzjMi49JSEGg&pi=u-fMQTIWKFRTay',
    picturesLink: 'https://drive.google.com/drive/folders/1dlI4mi7Fxb6Z0O9mF5-LkmpyalajPLyx?usp=share_link',
    ticketsSummer: [
        {
            flyer: '020125-mrjones.webp',
            goTo: '020125-mrjones',
            ticketLink: 'https://www.passline.com/eventos/mr-jones-jueves-2-de-enero-fiebre',
            ticketEvent: '02/01/25_mrjones',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250102,
            disabled: false
        },
        {
            flyer: '090125-mrjones.webp',
            goTo: '090125-mrjones',
            ticketLink: 'https://www.passline.com/eventos/mr-jones-jueves-9-de-enero',
            ticketEvent: '09/01/25_mrjones',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250109,
            disabled: false
        },
        {
            flyer: '130125-miramar.webp',
            goTo: '130125-miramar',
            ticketLink: '',
            ticketEvent: '13/01/25_miramar',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250113,
            disabled: true
        },
        {
            flyer: '140125-mrjones.webp',
            goTo: '140125-mrjones',
            ticketLink: 'https://www.passline.com/eventos/141-fiebre-x-fernet-branca-at-mr-jones',
            ticketEvent: '14/01/25_mrjones',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250114,
            disabled: false
        },
        {
            flyer: '210125-mrjones.webp',
            goTo: '210125-mrjones',
            ticketLink: 'https://www.passline.com/eventos/mr-jones-martes-21-de-enero',
            ticketEvent: '21/01/25_mrjones',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250121,
            disabled: false
        },
        {
            flyer: '300125-mrjones.webp',
            goTo: '300125-mrjones',
            ticketLink: 'https://www.passline.com/eventos/mr-jones-jueves-30-de-enero',
            ticketEvent: '30/01/25_mrjones',
            tablesLink: '',
            locationLink: '',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20250130,
            disabled: false
        },
    ],
}

const getInfoVisible = (list: TicketProps[]) => {

    let index = 0

    const targetTimeUTC = new Date(list[index].showSince);

    const currentTimeUTC = new Date(getCurrentUTCTime());

    if ((list[index].showUntil < parseInt(getDate())) || ((currentTimeUTC < targetTimeUTC))) {
        index++
    }

    return list[index]

}

export const introTexts: IntroTextsProps = {
    wantMyTicketAndMoreInfoIntroduction: getInfoVisible(globalTexts.tickets),
    // wantMyTicketAndMoreInfoIntroduction: globalTexts.tickets[0],
}