import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import Building from "../components/Building";
import { getFullDate } from "../utils/Utilities";
import Rights from "../components/Rights";
import { useSelector } from "react-redux";
import WsFloatButton from "../components/WsFloatButton";
import IntroductionSummer from "../components/summer2025/IntroductionSummer";
import SocialSectionSummer from "../components/summer2025/SocialSectionSummer";
import TicketSectionSummer from "../components/summer2025/TicketSectionSummer";

export const SummerScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation()

    const { loading } = useSelector((state: any) => state.ui);

    const preloader = () => {

        if (!loading) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 2300);

        }

    };

    useEffect(() => {

        preloader()

        allAnimations();

        return () => { allAnimations() };


    }, [location.pathname, loading])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress preloader={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <ScreenContainer
            style={{
                backgroundImage: "url('https://cdn.fiebrefiesta.com.ar/verano2025/fondo-flyers.webp')",
                backgroundSize: 'cover',
            }}
        >

            {showPreloader && <Building showInProgress={false} preloader={true} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <WsFloatButton />

            <IntroductionSummer />

            <TicketSectionSummer />

            <SocialSectionSummer />

            <Rights brand="Fiebre Fiesta" backgroundColor="rgba(0, 0, 0, 0.25)" />

        </ScreenContainer>

    )
}
