import { useEffect, useState } from "react"
import styled, { css } from "styled-components";
import { device } from '../../styles/stylesConfig';
import SocialMenu from "../SocialMenu";
import { useDispatch, useSelector } from "react-redux";
import { finishLoading } from "../../actions/ui";
import { useHistory } from "react-router-dom";

const LogoContainer = styled.div<{ inprogress?: string }>`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    ${({ inprogress }) => inprogress === 'true' && css`
        height: 35vh;

        @media ${device.sm} {
            height: 25vh;
        }
    `}

`

const LogoDiv = styled.div`
    position: relative;
    max-width: 70%;
    width: 60vh;
    height: 60vh;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin-bottom: 20vh;
`

const Logo = styled.img<{ inprogress?: string, preloader?: string }>`
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;
    
    ${({ inprogress }) => inprogress === 'true' && css`
        height: 40%;
        align-self: center;
        justify-self: center;
    `}

    ${({ preloader }) => preloader === 'true' && css`
        width: 200px;
        height: 200px;

    `}
`

const Summer2025 = styled.img`
    max-width: 40%;
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 7vh;
    right: 2vh;
    z-index: 3;
    transform: rotate(-15deg);

    @media ${device.lg} {
        width: 12vh;
        bottom: 11vh;
        right: 2vh;
    }
    
    @media ${device.md} {
        width: 12vh;
        bottom: 11vh;
        right: 2vh;
    }

    @media ${device.sm} {
        width: 10vh;
        bottom: 17vh;
        right: 0vh;
    }

    @media ${device.xs} {
        bottom: 17vh;
        right: 0vh;
    }
`

const January = styled.img`
    max-width: 40%;
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 8vh;
    left: 2vh;
    z-index: 3;
    transform: rotate(15deg);

    @media ${device.lg} {
        width: 12vh;
        bottom: 12vh;
        left: 2vh;
    }
    
    @media ${device.md} {
        width: 12vh;
        bottom: 12vh;
        left: 2vh;
    }

    @media ${device.sm} {
        width: 10vh;
        bottom: 18vh;
        left: 0vh;
    }

`

const Bg = styled.img`
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    object-position: bottom;
`
const Mdq = styled.img`
    height: auto;
    position: absolute;
    margin: auto;
    z-index: 3;
    width: 400px;
    height: auto;
    bottom: -10vh;

    @media ${device.lg} {

    }
    
    @media ${device.md} {
        width: 300px;
        bottom: -5vh;
    }

    @media ${device.sm} {
        width: 200px;
        bottom: 0vh;
    }

`


const BackButton = styled.button`
    width: fit-content;
    position: fixed;
    top: 7vh;
    font-size: 14px;
    color: #FFF;
    background-color: #D90B0B;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: top 0.75s ease-in-out, transform 0.3s ease, background-color 0.3s ease;
    padding: 15px 25px;
    border-radius: 10px;
    letter-spacing: 1px;
    z-index: 10;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #920505;
        transform: scale(1.02);
    }

    &.active {
        top: 2vh;
    }


    @media ${device.lg} {
        font-size: 12px;
        padding: 12px 25px;
    }

    @media ${device.md} {
        align-self: center;
        margin-top: 30px;

    }

    @media ${device.sm} {
        font-size: 10px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`


const Info = styled.div`
    position: absolute;
    top: 20px;
    z-index: 10;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20vw;
    align-items: center;
    justify-items: center;

    p {
        color: #FFF;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Montserrat';
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 200;

        br {
            display: none;
        }

        strong {
            font-weight: 400;
        }

        @media ${device.lg} {
            font-size: 14px;
        }

        @media ${device.md} {
            font-size: 14px;

            br {
                display: unset;
            }
        }

        @media ${device.sm} {
            font-size: 12px;
        }

    }
    
    @media ${device.lg} {
        gap: 10vw;
    }

    @media ${device.md} {
        gap: 1vw;
    }

    @media ${device.sm} {
        gap: 1vw;
    }

`

interface Props {
    inProgress?: boolean;
    preloader?: boolean;
}

const LogosComponent = ({ inProgress, preloader }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const [imageLoaded, setImageLoaded] = useState(false);

    const dispatch = useDispatch()

    const history = useHistory()

    const [show, setShow] = useState(false);

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            setShow(true);
        } else {
            setShow(false);
        }

    }

    useEffect(() => {

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    return (

        <LogoContainer inprogress={inProgress ? inProgress.toString() : 'false'} id='home'>

            <Bg
                loading="eager"
                // src={require(`../images/introduction/cinta.png`)}
                src={`${process.env.REACT_APP_CDN}/verano2025/fondo-mdq.webp`}
                alt="Bg"
                onLoad={() => { dispatch(finishLoading()); }}
            />

            <Info className={!loading ? 'utils__fade-in-bottom' : ''}>

                <p><strong>Fiebre </strong><br />Verano 2025</p>

                <p><strong>Old School </strong><br />Reggaeton Party</p>

            </Info>

            <BackButton
                className={show ? 'active' : ''}
                onClick={() => {

                    history.push('/')

                }}
            >
                Regresar al inicio
            </BackButton>

            <LogoDiv className={imageLoaded && preloader ? '' : ''}>

                <Summer2025
                    className={!loading ? 'utils__fade-in-center' : ''}
                    loading="eager"
                    // src={require(`../images/introduction/old-school.png`)}
                    src={`${process.env.REACT_APP_CDN}/verano2025/verano-blanco.webp`}
                    alt="Summer 2025"
                />

                <January
                    className={!loading ? 'utils__fade-in-center' : ''}
                    loading="eager"
                    // src={require(`../images/introduction/old-school.png`)}
                    src={`${process.env.REACT_APP_CDN}/verano2025/enero-naranja.webp`}
                    alt="January 2025"
                />

                <Logo
                    preloader={preloader ? preloader.toString() : 'false'}
                    inprogress={inProgress ? inProgress.toString() : 'false'}
                    className={!loading && !inProgress ? 'utils__fade-in-bottom active' : ''}
                    loading="eager"
                    alt="Fiebre"
                    src={`${process.env.REACT_APP_CDN}/verano2025/logo-fiebre-rojo.png`}
                    onLoad={() => { setImageLoaded(true) }}
                />

                <Mdq
                    className={!loading ? 'utils__fade-in-center' : ''}
                    loading="eager"
                    // src={require(`../images/introduction/old-school.png`)}
                    src={`${process.env.REACT_APP_CDN}/verano2025/mdq-naranja.png`}
                    alt="Summer 2025"
                />

            </LogoDiv>

        </LogoContainer>
    )
}

const LogosSummer = LogosComponent;

export default LogosSummer;