import styled from "styled-components"
import Spotify from "../Spotify"
import Social from "../Social"
import WhatsAppGroup from "../WhatsAppGroup"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
`

export const SocialSectionSummer = () => {

    return (

        <Container>

            <ContentContainer>

                <WhatsAppGroup />

                <Social />

            </ContentContainer>

        </Container>

    )
}

export default SocialSectionSummer;