import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useDispatch } from "react-redux"
import { TicketProps } from "../constants/TextConstants"
import { startLoading } from "../actions/ui"
import { useHistory } from "react-router-dom"
import { useLogEvent } from "../hooks/useLogEvent"

const Container = styled.section`
    width: 100vw;
    padding: 5vh 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Title = styled.p`
    width: 100%;
    font-weight: 900;
    font-size: 30px;
    color: #EA5B06;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    letter-spacing: 15px;
    line-height: 70px;

    @media ${device.lg} {
        font-size: 22px;
        letter-spacing: 10px;
        line-height: 50px;
    }

    @media ${device.md} {
        font-size: 18px;
        letter-spacing: 10px;
        line-height: 40px;
        text-align: center;
    }

    @media ${device.sm} {
        font-size: 14px;
        letter-spacing: 7px;
    }

    @media ${device.xs} {
        font-size: 12px;
    }
`

const TicketsList = styled.div<{ listlength: number }>`
    width: auto;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    grid-template-columns: repeat(1, 1fr);

    ${props => props.listlength % 2 !== 0 && css`

        & > div:nth-of-type(${props.listlength}) {
            grid-column: 1 / 3;
        }

        
    @media ${device.md} {
        & > div:nth-of-type(${props.listlength}) {
            grid-column: unset;
        }
    }

    `}

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }

    @media ${device.md} {
        gap: 70px;
    }

`

const Ticket = styled.button<{ img: string }>`
    width: 25vw;
    height: auto;
    aspect-ratio: 9 / 16;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 0;
    box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.25);
    margin-top: 100px;

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: contain;
    `}

    @media ${device.lg} {
        width: 35vw;
    }

    @media ${device.md} {
        width: 50vw;

        margin-top: 50px;
    }

    @media ${device.sm} {
        width: 100vw;
        margin-top: 50px;
    }

    @media ${device.xs} {
        width: 100vw;
    }

    &:first-of-type {
        margin-top: 0px;
    }

`


const SendButton = styled.button`
    width: fit-content;
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    background-color: #5F94D9;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 15px 25px;
    border-radius: 10px;
    margin-top: 50px;
    letter-spacing: 1px;
    z-index: 3;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #1d55a0;
        transform: scale(1.02);
    }


    @media ${device.lg} {
        font-size: 12px;
        padding: 12px 25px;
    }

    @media ${device.md} {
        align-self: center;
        margin-top: 30px;

    }

    @media ${device.sm} {
        font-size: 10px;
        padding: 7.5px 20px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

const Tape = styled.img`
    width: 350px;
    height: auto;
    position: absolute;
    margin: auto;
    top: -130px;
    left: 0;
    right: 0;
    z-index: 1;
    transform: rotate(35deg);

    @media ${device.lg} {
        width: 300px;
        top: -110px;
    }
    
    @media ${device.md} {
        width: 200px;
        top: -75px;
    }

    @media ${device.sm} {
        width: 150px;
        top: -60px;
    }

    @media ${device.xs} {
        width: 100px;
        top: -40px;
    }

`


export const SummerTicketSection = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();


    const ticket: TicketProps = {
        flyer: 'verano2025/fechas-verano1.webp',
        goTo: 'verano2025',
        ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-groove-2211',
        ticketEvent: '22/11/24_groove',
        tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
        locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9688531391407!2d-58.4257572874254!3d-34.57965465611708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb59b4e07a685%3A0x57be92636048e0f9!2sGroove!5e0!3m2!1ses!2sar!4v1720909728659!5m2!1ses!2sar',
        showSince: '2024-11-10T22:29:00Z',
        showUntil: 20241222
    };

    return (

        <Container>

            <Title className='utils__fade-in-fast-top'>Mar Del Plata</Title>

            <TicketsList listlength={1}>

                <Container>

                    <Ticket
                        img={`${process.env.REACT_APP_CDN}/${ticket.flyer}`}
                        onClick={async () => {

                            dispatch(startLoading());

                            await history.push(`/${ticket.goTo}`);

                            handleLogEvent(`click_boton_web_verano`)

                        }}
                    >
                        <Tape
                            loading="eager"
                            src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                            alt="Cinta"
                        />
                    </Ticket>

                    <SendButton
                        onClick={async () => {

                            await history.push(`/${ticket.goTo}`);

                            history.push(`/${ticket.goTo}`);

                            handleLogEvent(`click_boton_web_verano`)

                        }}
                    >
                        Quiero mi entrada
                    </SendButton>

                </Container>

            </TicketsList>



        </Container >


    )
}

export default SummerTicketSection;